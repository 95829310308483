import React, { useEffect, useState } from "react";
import Loading from "../common/Loading";
import { SuppliersApi } from "@unity/components";
import { RenderPageTemplate } from "../mapping";
import { useHistory } from "react-router";
import ApiLoader from "../common/ApiLoader";

export default function SuppliersEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [products, setProducts] = useState(false);
  const [categories, setCategories] = useState(false);
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  let history = useHistory();

  const getSupplierSingle = async () => {
    const res = await SuppliersApi.getSupplierSingle(route.match.params.id);
    setData(res.data);
  };

  const handleProductChange = (name, value, key) => {
    const newData = [...products];
    newData[key][name] = value;
    setProducts(newData);
  };

  const handleProductDelete = async (key) => {
    const newData = [...products];
    if (newData[key].id) {
      await SuppliersApi.deleteProduct(newData[key].id);
    }
    newData.splice(key, 1);
    setProducts(newData);
  };

  const handleProductAdd = () => {
    const newData = [...products];
    newData.push({});
    setProducts(newData);
  };

  useEffect(() => {
    getSupplierSingle();
  }, []);

  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const handleProductSave = async (key) => {
    products[key]["supplier_id"] = route.match.params.id;
    const res = await SuppliersApi.createProduct(products[key]);

    if (res.errors) {
      setLoading({ status: true, data: 'Validation Errors, Please wait....' });
      setTimeout(() => {
        const newErrors = { ...res.errors };
        newErrors["key"] = key;
        setErrors(newErrors);
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: 'Saving Success, please wait...' });
      setTimeout(() => {
        const newProducts = products;
        newProducts[key] = res.data;
        setProducts(newProducts);
        setErrors(false);
        setLoading({ status: false });
      }, 3000);
    }
  };

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving your record, Please Wait...." });

    const x = await SuppliersApi.updateSupplier(data);

    setLoading({ status: true, data: "Saving, Please Wait...." });

    if (x.errors) {
      setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
      setTimeout(() => {
        setErrors(x.errors);
        setLoading({ status: false });
      }, 3000);
    } else {
      setLoading({ status: true, data: "Save Success!, Please Wait...." });
      setTimeout(() => {
        history.push("/suppliers/index");
        setLoading({ status: false });
      }, 3000);
    }
  };

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "edit");

  if (data) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />

        <RenderPage
          data={data}
          products={products}
          categories={categories}
          errors={errors}
          setProducts={setProducts}
          setCategories={setCategories}
          handleChange={handleChange}
          handleProductChange={handleProductChange}
          handleProductDelete={handleProductDelete}
          handleProductSave={handleProductSave}
          handleProductAdd={handleProductAdd}
          setData={setData}
          handleSave={handleSave}
          type="edit"
          context={context}
          history={history}
          route={route}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
