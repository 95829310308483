import React, { useEffect } from "react";
import { TextField, FormControl, FormHelperText, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { AppButtonComponent, SuppliersApi } from "@unity/components";

const useStyles = makeStyles((theme) => ({
  selectPad: {
    marginTop: 16
  },
}));

export default function ProductsForm({
  errors,
  supplier,
  products,
  categories,
  setProducts,
  setCategories,
  handleProductChange,
  handleProductAdd,
  handleProductDelete,
  handleProductSave,
  type,
}) {
  const classes = useStyles();

  const getData = async () => {
    const res1 = await SuppliersApi.getProductsForSupplier(supplier);
    setProducts(res1.data);
    const res2 = await SuppliersApi.getProductCategories();
    setCategories(res2.data);
  };

  useEffect(() => {
    getData();
  }, []);

  if (products) {
    return (
      <>
        <div className="block">
          <div className="container-fluid">
            {products &&
              products.map((item, key) => {
                return (
                  <div className="form-row">
                    <div className="form-group col-lg-2">
                      <TextField
                        label="Reference"
                        value={item.reference || ""}
                        name="reference"
                        onChange={(e) =>
                          handleProductChange(e.target.name, e.target.value, key)
                        }
                        fullWidth
                        error={errors && (errors.key === key) && errors.reference || false}
                        helperText={errors && (errors.key === key) && errors.reference || ""}
                      />
                    </div>
                    <div className="form-group col-lg-5">
                      <TextField
                        label="Name"
                        value={item.name || ""}
                        name="name"
                        onChange={(e) =>
                          handleProductChange(e.target.name, e.target.value, key)
                        }
                        fullWidth
                        error={errors && (errors.key === key) && errors.name || false}
                        helperText={errors && (errors.key === key) && errors.name || ""}
                      />
                    </div>
                    <div className="form-group col-lg-2">
                      <FormControl
                        className={classes.selectPad}
                        error={errors && (errors.key === key) && errors.category_id || false}
                        fullWidth
                      >
                        <Select
                          value={item.category_id || "Category"}
                          fullWidth
                          name="category_id"
                          onChange={(e) =>
                            handleProductChange(e.target.name, e.target.value, key)
                          }
                        >
                          {item.category_id ? null : <MenuItem key="Category" disabled value="Category" >Category</MenuItem>}
                          {categories && categories.map((item, key) => {
                            return (
                              <MenuItem key={key} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText>{errors && (errors.key === key) && errors.category_id || ""}</FormHelperText>
                      </FormControl>
                    </div>
                    <div className="form-group col-lg-1">
                      <TextField
                        label="Cost"
                        type="number"
                        name="unit_cost"
                        value={item.unit_cost || ""}
                        onChange={(e) =>
                          handleProductChange(e.target.name, e.target.value, key)
                        }
                        fullWidth
                        error={errors && (errors.key === key) && errors.unit_cost || false}
                        helperText={errors && (errors.key === key) && errors.unit_cost || ""}
                      />
                    </div>

                    <div
                      className="form-group col-lg-2"
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      {type !== "view" && (
                        <>
                          {!item.id && (
                            <AppButtonComponent
                              text="save"
                              btnIcon="save"
                              style={{
                                color: "white",
                                background: "green",
                                marginRight: 5,
                              }}
                              onClick={() => handleProductSave(key)}
                            />
                          )}
                          <AppButtonComponent
                            text="delete"
                            btnIcon="delete"
                            style={{ color: "white", background: "red" }}
                            onClick={() => handleProductDelete(key)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            {type !== "view" && (
              <AppButtonComponent
                btnIcon="add"
                text="add new"
                style={{ background: "blue", color: "white" }}
                onClick={() => handleProductAdd()}
              />
            )}
          </div>
        </div>
      </>
    );
  } else {
    return <p>Loading</p>;
  }
}
